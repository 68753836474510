.root {
  display: 'flex';
  margin-bottom: 40px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}
